/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";
import keyBy from "lodash/keyBy";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import useEnvironmentVariables from "../../components/hooks/useEnvironmentVariables.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const WorkflowHeader = React.lazy(() => import(/* webpackPrefetch: true */ "./WorkflowHeader.jsx"));
const WorkflowsRow = React.lazy(() => import(/* webpackPrefetch: true */ "./WorkflowsRow.jsx"));

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const searchFields = {
  facility: (object) => object.facility?.name,
  using: {
    triage: (object) => object.triageEnabled,
    "e-sign": (object) => object.eSignEnabled,
  },
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
  {label: "Facility name", keyword: "facility", variant: "negatable"},
  {label: "Using Triage", keyword: "using:triage", variant: "toggle"},
  {label: "Using E-Signing", keyword: "using:e-sign", variant: "toggle"},
];

const defaultSort = {
  field: "name",
  reverse: false,
};
const fieldGetters = {
  name: (workflow) => workflow.facility?.name,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowWorkflows(isInAnyRole, inboxAccess) {
  if (arguments.length !== 2) {
    throw new Error("Wrong number of arguments");
  }
  return isInAnyRole(["tzAdmin", "facilityAdmin"]) && inboxAccess;
}

function Workflows() {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole, inboxAccess, userFacilityId} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [workflowSettings, setWorkflowSettings] = React.useState([]);

  const getWorkflowSettings = React.useCallback(async () => {
    const workflowsQuery = {facilityId: {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]}};
    if (isInAnyRole(["tzAdmin"])) {
      delete workflowsQuery.facilityId;
    }

    try {
      const {data: workflowsResponse} = await axios({
        method: "get",
        url: "/facilities/workflowSettings",
        params: workflowsQuery,
      });

      setWorkflowSettings(workflowsResponse);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, [isInAnyRole, userFacilityId]);

  useInterval(getWorkflowSettings, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    if (features.algorithmClassification) {
      searchFields.using["auto-classification"] = (object) => object.autoClassificationEnabled;
      searchHelper.push({
        label: "Using Auto-Classification",
        keyword: "using:auto-classification",
        variant: "toggle",
      });
    }
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields, features.algorithmClassification]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredWorkflows = useFilter(workflowSettings, search, searchFields);
  const [sortedWorkflows, handleSortSelection, sort] = useSort(filteredWorkflows, {
    defaultSort,
    fieldGetters,
  });

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageWorkflows = React.useMemo(
    () => sortedWorkflows.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedWorkflows]
  );
  React.useEffect(() => setPage(0), [search]);

  //---------------------------------------------------------------------------
  // Marshall facilities array to an object
  //---------------------------------------------------------------------------
  const workflowsObject = React.useMemo(() => keyBy(workflowSettings, "facilityId"), [workflowSettings]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowWorkflows(isInAnyRole, inboxAccess)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Workflows - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedWorkflows.length === 0 && <NoResults />
      }
      {
        //---------------------------------------------------------------------------
        // Render the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedWorkflows.length > 0 && (
          <>
            <WorkflowHeader sort={sort} setSort={handleSortSelection} />
            {pageWorkflows.map((workflow) => (
              <WorkflowsRow
                key={workflow.facilityId}
                workflowSetting={workflow}
                workflowSettings={workflowsObject}
                alwaysOpen={pageWorkflows.length === 1}
                setTableReload={setTableLoading}
              />
            ))}
            <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedWorkflows.length} />
          </>
        )
      }
    </>
  );
}

export default Workflows;
