/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const TerminateDevicesCard = React.lazy(
  () => import(/* webpackPrefetch: true */ "./TerminateDevicesCard.jsx")
);

//--------------------------------------------------------------------------
export function allowTerminateDevices(isInAnyRole) {
  return isInAnyRole(["tzAdmin", "warehouse"]);
}

function TerminateDevicesPage() {
  //---------------------------------------------------------------------------
  // Error management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole} = useJwt();

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowTerminateDevices(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Terminate Devices - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <TerminateDevicesCard setError={setError} />
    </>
  );
}

export default TerminateDevicesPage;
