/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const StudyConfigurationsHeader = React.lazy(
  () => import(/* webpackPrefetch: true */ "./StudyConfigurationsHeader.jsx")
);
const StudyConfigurationsRow = React.lazy(
  () => import(/* webpackPrefetch: true */ "./StudyConfigurationsRow.jsx")
);

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const searchFields = {
  facility: "name",
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
];

const defaultSort = {
  field: "name",
  reverse: false,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowStudyConfigurations(isInAnyRole, inboxAccess) {
  if (arguments.length !== 2) {
    throw new Error("Wrong number of arguments");
  }
  return isInAnyRole(["tzAdmin", "facilityAdmin"]) && inboxAccess;
}

function StudyConfigurationsTable() {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole, inboxAccess, userFacilityId} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [studyConfigurations, setStudyConfigurations] = React.useState([]);
  const getStudyConfigurations = React.useCallback(async () => {
    const params = {facilityId: {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]}};
    if (isInAnyRole(["tzAdmin"])) {
      delete params.facilityId;
    }

    try {
      const {data: studyConfigurationsResponse} = await axios({
        method: "get",
        url: "/facilityStudyConfigurations",
        params,
      });

      setStudyConfigurations(studyConfigurationsResponse);
    } catch (err) {
      setError(err.message);
    }

    setTableLoading(false);
  }, [isInAnyRole, userFacilityId]);

  useInterval(getStudyConfigurations, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredConfigurations = useFilter(studyConfigurations, search, searchFields);
  const [sortedConfigurations, handleSortSelection, sort] = useSort(filteredConfigurations, {defaultSort});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageConfigurations = sortedConfigurations.slice(page * pageSize, (page + 1) * pageSize);
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowStudyConfigurations(isInAnyRole, inboxAccess)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Study Configurations - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedConfigurations.length === 0 && <NoResults />
      }
      {
        //---------------------------------------------------------------------------
        // Render the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedConfigurations.length > 0 && (
          <>
            <StudyConfigurationsHeader sort={sort} setSort={handleSortSelection} />
            {pageConfigurations.map((config) => (
              <StudyConfigurationsRow
                key={config.facilityId}
                studyConfiguration={config}
                alwaysOpen={pageConfigurations.length === 1}
                setTableReload={setTableLoading}
              />
            ))}
            <Pagination
              pageSize={pageSize}
              page={page}
              setPage={setPage}
              count={sortedConfigurations.length}
            />
          </>
        )
      }
    </>
  );
}

export default StudyConfigurationsTable;
