/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import keyBy from "lodash/keyBy";
import {DateTime} from "luxon";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import useJwt from "../../components/contexts/useJwt.jsx";
import useDisplayedReportStates from "../../components/hooks/useDisplayedReportStates.jsx";
import useDisplayedStudyStatuses from "../../components/hooks/useDisplayedStudyStatuses.jsx";
import useStudyTypeNames from "../../components/hooks/useStudyTypeNames.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const UsageReportForm = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/UsageReportForm.jsx")
);
const ServiceBillingHeader = React.lazy(
  () => import(/* webpackPrefetch: true */ "./ServiceBillingHeader.jsx")
);
const ServiceBillingRow = React.lazy(() => import(/* webpackPrefetch: true */ "./ServiceBillingRow.jsx"));

//--------------------------------------------------------------------------
export function allowServiceBilling(isInAnyRole, userFacilityBilling, inboxAccess) {
  if (arguments.length !== 3) {
    throw new Error("Wrong number of arguments");
  }

  return isInAnyRole(["tzAdmin", "facilityAdmin"]) && userFacilityBilling === "independent" && inboxAccess;
}

const defaultSort = {
  field: "name",
  reverse: false,
  caseSensitive: false,
};
const fieldGetters = {
  name: (report) => report.facility?.name,
  studies: (report) => report.studies?.length,
};

function ServiceBilling() {
  //---------------------------------------------------------------------------
  // Error management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [info, setInfo] = React.useState(null);

  //---------------------------------------------------------------------------
  // State management
  //---------------------------------------------------------------------------
  // Default to the previous month.
  const [reportDate, setReportDate] = React.useState(DateTime.local().minus({months: 1}).startOf("month"));
  const [tableLoading, setTableLoading] = React.useState(true);

  const {isInAnyRole, userFacilityBilling, inboxAccess} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [billableFacilities, setBillableFacilities] = React.useState([]);
  const [exemptFacilities, setExemptFacilities] = React.useState([]);
  const [facilityInvoices, setFacilityInvoices] = React.useState(new Map());
  const getUsageReports = React.useCallback(async () => {
    // Reset states for new API call
    setBillableFacilities([]);
    setExemptFacilities([]);
    setFacilityInvoices(new Map());

    try {
      const {year, month} = reportDate;

      const [{data: billingReport}, {data: invoices}] = await Promise.all([
        axios({
          method: "get",
          url: `/studies/billing/${year}/${month}`,
        }),
        axios({
          method: "get",
          url: `/facilities/invoices/service/${year}/${month}`,
        }),
      ]);

      setBillableFacilities(billingReport.billableFacilities);
      setExemptFacilities(billingReport.exemptFacilities);

      const formattedInvoices = invoices.length > 0 ? keyBy(invoices, "facilityId") : {};
      setFacilityInvoices(formattedInvoices);

      if (!billingReport.billableFacilities.length && !billingReport.exemptFacilities.length) {
        setInfo(
          "Service Billing reports are currently unavailable because your facility does not have any sub-facilities designated for fee-per-service."
        );
      }
    } catch (err) {
      setError(err.message);
    }

    setTableLoading(false);
  }, [reportDate]);

  React.useEffect(() => {
    if (tableLoading) {
      getUsageReports();
    }
  }, [tableLoading, getUsageReports]);

  //---------------------------------------------------------------------------
  // Sorting support
  //---------------------------------------------------------------------------
  const [sortedBillableFacilities, billableHandleSortSelection, billableSort] = useSort(billableFacilities, {
    defaultSort,
    fieldGetters,
  });
  const [sortedExemptFacilities, exemptHandleSortSelection, exemptSort] = useSort(exemptFacilities, {
    defaultSort,
    fieldGetters,
  });

  const displayedReportStates = useDisplayedReportStates();
  const displayedStudyStatuses = useDisplayedStudyStatuses();
  const displayedStudyTypes = useStudyTypeNames("complex");
  const clickedExport = React.useCallback(async () => {
    const csvToExport = [];

    csvToExport.push([
      "Study ID",
      "Facility Name",
      "Device Serial",
      "Finalization Date",
      "Study Type",
      "Report Number",
      "Report Status",
      "Start Date",
      "End Date",
      "Duration (days)",
      "Downgrade Authorization",
      "Study Indication",
      "Study Status",
      "Study Creation Date",
    ]);
    sortedBillableFacilities.forEach(({facility, studies}) => {
      studies
        .sort((a, b) => a.id - b.id)
        .forEach((study) => {
          const durationInHours = study.recordedDuration || study.configuredDuration;
          const durationInDays = durationInHours ? Math.ceil(durationInHours / 24) : "";
          let downgradeAuthorization = "None";
          if (study.downgradeAuthorized === true) {
            downgradeAuthorization = "Yes";
          } else if (study.downgradeAuthorized === false) {
            downgradeAuthorization = "No";
          }

          csvToExport.push([
            study.id,
            facility.name,
            study.currentEnrollment?.tzSerial,
            DateTime.fromISO(study.finalizedAt).toFormat("yyyy-MM-dd HH:mm ZZZZ"),
            displayedStudyTypes[study.studyType]?.short || displayedStudyTypes[study.studyType],
            study.generatedReports[0]?.reportNumber || "",
            displayedReportStates[study.generatedReports[0]?.state] || "",
            DateTime.fromISO(study.studyStartDate).toFormat("yyyy-MM-dd HH:mm ZZZZ"),
            study.studyEndDate ? DateTime.fromISO(study.studyEndDate).toFormat("yyyy-MM-dd HH:mm ZZZZ") : "",
            durationInDays,
            downgradeAuthorization,
            study.studyIndication,
            displayedStudyStatuses[study.studyState],
            DateTime.fromISO(study.createdAt).toFormat("yyyy-MM-dd HH:mm ZZZZ"),
          ]);
        });
    });

    let csvBuilder = "";
    csvToExport.forEach((line) => {
      line.forEach((item) => {
        if (typeof item === "boolean") {
          // Handle booleans
          csvBuilder += `"${item ? "Yes" : "No"}",`;
        } else if (typeof item === "string" && item.includes(",")) {
          // Strings with commas must be handled differently
          csvBuilder += `"${item.replaceAll(/"/g, "'")}",`;
        } else if (typeof item === "string") {
          // Handle strings
          csvBuilder += `="${item.replaceAll(/"/g, "'")}",`;
        } else {
          // Handle numbers, etc.
          csvBuilder += `="${item}",`;
        }
      });
      csvBuilder += `\n`;
    });

    const safeDate = DateTime.now().toFormat("yyyy_MM_dd");
    const file = new Blob([csvBuilder], {type: "text/csv"});
    const hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(file);
    hiddenElement.target = "_blank";
    hiddenElement.download = `BitRhythmServiceReport_${safeDate}.csv`;
    hiddenElement.click();
  }, [sortedBillableFacilities, displayedStudyStatuses, displayedReportStates, displayedStudyTypes]);

  //---------------------------------------------------------------------------
  // Format an object with all facilities so that we can attach parent facility
  // names on each row, if applicable
  //---------------------------------------------------------------------------
  const facilities = React.useMemo(() => {
    const allFacilities = [
      ...billableFacilities.map((obj) => obj.facility),
      ...exemptFacilities.map((obj) => obj.facility),
    ];

    return keyBy(allFacilities, "id");
  }, [billableFacilities, exemptFacilities]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowServiceBilling(isInAnyRole, userFacilityBilling, inboxAccess)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Service Billing Report - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Render the usage report table
        //---------------------------------------------------------------------------
        !tableLoading && (
          <>
            <Grid container sx={{mt: 3}}>
              <Grid size={8}>
                <Box sx={{display: "inline-flex", alignItems: "end"}} data-cy="service-billing-title">
                  <Typography variant="h5">Service Billing Report</Typography>
                  &nbsp;&nbsp;
                  <Typography variant="subtitle1">{reportDate.toFormat("MMMM yyyy")}</Typography>
                </Box>
              </Grid>
              <Grid size="grow" align="end">
                <Button
                  color="secondary"
                  data-cy="export-service-billing-report-button"
                  variant="outlined"
                  onClick={clickedExport}
                  sx={{mr: 3}}
                >
                  Export
                </Button>
                <UsageReportForm
                  usageReportDate={reportDate}
                  setUsageReportDate={setReportDate}
                  setTableReload={setTableLoading}
                  disabled={!billableFacilities.length && !exemptFacilities.length}
                />
              </Grid>
            </Grid>

            <Alert message={info} level="warning" otherProps={{mt: 3}} />

            {sortedBillableFacilities.length > 0 && (
              <Box
                sx={{mt: 4, p: 2, backgroundColor: "background.grey", borderRadius: "4px"}}
                data-cy="billable-facilities-table"
              >
                <Typography variant="h6" sx={{mb: 2}}>
                  Invoicing Required
                </Typography>

                <ServiceBillingHeader sort={billableSort} setSort={billableHandleSortSelection} />
                {sortedBillableFacilities.map(({facility, studies}) => (
                  <ServiceBillingRow
                    key={facility.name}
                    facility={facility}
                    facilities={facilities}
                    studies={studies}
                    invoices={facilityInvoices}
                    reportDate={reportDate}
                    setTableReload={setTableLoading}
                    setError={setError}
                  />
                ))}
              </Box>
            )}

            {sortedExemptFacilities.length > 0 && (
              <Box
                sx={{mt: 4, p: 2, backgroundColor: "background.grey", borderRadius: "4px"}}
                data-cy="exempt-facilities-table"
              >
                <Typography variant="h6">Invoicing Not Required</Typography>
                <Typography variant="body2" sx={{mb: 2, color: "text.secondary"}}>
                  <i>
                    An invoice is not required if there are no fee-per-service studies for the given month.
                  </i>
                </Typography>

                <ServiceBillingHeader sort={exemptSort} setSort={exemptHandleSortSelection} />
                {sortedExemptFacilities.map(({facility, studies}) => (
                  <ServiceBillingRow
                    key={facility.name}
                    facility={facility}
                    facilities={facilities}
                    studies={studies}
                    invoices={facilityInvoices}
                    reportDate={reportDate}
                    setTableReload={setTableLoading}
                    setError={setError}
                  />
                ))}
              </Box>
            )}
          </>
        )
      }
    </>
  );
}

export default ServiceBilling;
