/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useLocalStorage, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import useUserReadableRoles from "../../components/hooks/useUserReadableRoles.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const UsersForm = React.lazy(() => import(/* webpackPrefetch: true */ "../../dialogs/UsersForm.jsx"));
const UsersHeader = React.lazy(() => import(/* webpackPrefetch: true */ "./UsersHeader.jsx"));
const UsersRow = React.lazy(() => import(/* webpackPrefetch: true */ "./UsersRow.jsx"));

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Export search configuration for use in navbar
//---------------------------------------------------------------------------
const searchFields = {
  username: "username",
  email: "email",
  name: "fullName",
  facility: (object) => object.facility?.name,
  role: "displayRole",
  is: {
    active: (object) => object.state === "active" || object.state === "reset",
    pending: (object) => object.state === "pending",
    disabled: (object) => object.state === "disabled",
  },
};
const searchHelper = [
  {label: "Has the words", keyword: "+", variant: "global"},
  {label: "Doesn't have", keyword: "-", variant: "global"},
  {label: "Username", keyword: "username", variant: "negatable"},
  {label: "Full name", keyword: "name", variant: "negatable"},
  {label: "Email address", keyword: "email", variant: "negatable"},
  {label: "Facility name", keyword: "facility", variant: "negatable"},
  {label: "User role", keyword: "role", variant: "negatable"},
];

const defaultSort = {
  field: "fullName",
  reverse: false,
};
const fieldGetters = {
  facilityName: (user) => user.facility?.name,
  status: (user) => {
    if (user.state === "active") {
      return "Active";
    }
    if (user.state === "pending") {
      return "Pending";
    }
    return "Disabled";
  },
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowUsers(isInAnyRole) {
  return isInAnyRole(["tzAdmin", "facilityAdmin"]);
}

function Users() {
  //---------------------------------------------------------------------------
  // State Management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [info, setInfo] = React.useState(null);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);

  const {isInAnyRole, userFacilityId} = useJwt();

  const userReadableRoles = useUserReadableRoles();

  const getUsers = React.useCallback(async () => {
    const userQuery = {facilityId: {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]}};
    const facilityQuery = {id: userQuery.facilityId};
    if (isInAnyRole(["tzAdmin"])) {
      delete userQuery.facilityId;
      delete facilityQuery.id;
    }

    try {
      const [{data: usersResponse}, {data: pendingUserResponse}] = await Promise.all([
        axios({
          method: "get",
          url: "/users",
          params: userQuery,
        }),
        axios({
          method: "get",
          url: "/users/pendingUsers",
          params: userQuery,
        }),
      ]);

      // Place facilityName and displayable role onto user objects for search
      const allUsers = [...usersResponse, ...pendingUserResponse].map((user) => ({
        ...user,
        displayRole: userReadableRoles[user.role],
      }));

      setUsers(allUsers);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, [isInAnyRole, userFacilityId, userReadableRoles]);

  useInterval(getUsers, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  // Use filterSearch to separate the filter button filters from the search filters

  const {username} = useJwt();
  const [userStatusFilter, setUserStatusFilter] = useLocalStorage(`${username}.users.filter`, {
    active: true,
    pending: true,
    disabled: false,
  });

  const filterSearch = React.useMemo(() => {
    const userStatusToFilterOut = Object.entries(userStatusFilter)
      .filter((entry) => !entry[1])
      .map((entry) => `-is:${entry[0]}`);

    return `${search} ${userStatusToFilterOut.join(" ")}`;
  }, [search, userStatusFilter]);

  const filteredUsers = useFilter(users, filterSearch, searchFields);
  const [sortedUsers, handleSortSelection, sort] = useSort(filteredUsers, {defaultSort, fieldGetters});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageUsers = React.useMemo(
    () => sortedUsers.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedUsers]
  );
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowUsers(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Users - BitRhythm Admin</title>
      </Helmet>
      <React.Suspense fallback={<TableLoading />}>
        <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
        <Alert message={info} setMessage={setInfo} level="info" variant="snackbar" />
        {
          //---------------------------------------------------------------------------
          // Display a loading spinner if we're still waiting on the API
          //---------------------------------------------------------------------------
          tableLoading && <TableLoading />
        }
        {!tableLoading && (
          <>
            <UsersHeader
              sort={sort}
              setSort={handleSortSelection}
              sortedUsers={sortedUsers}
              userStatusFilter={userStatusFilter}
              setUserStatusFilter={setUserStatusFilter}
            />
            {
              //---------------------------------------------------------------------------
              // Display a message if there are no matching results, instead of the table
              // Include the header so that the filter can be selected.
              //---------------------------------------------------------------------------
              sortedUsers.length === 0 && (
                <>
                  <NoResults />
                  <UsersForm users={users} setTableReload={setTableLoading} setInfo={setInfo} />
                </>
              )
            }
            {
              //---------------------------------------------------------------------------
              // Render the table and the FAB
              //---------------------------------------------------------------------------
              sortedUsers.length > 0 && (
                <>
                  {pageUsers.map((user) => (
                    <UsersRow
                      key={user.id}
                      user={user}
                      users={users}
                      alwaysOpen={pageUsers.length === 1}
                      setTableReload={setTableLoading}
                      setInfo={setInfo}
                    />
                  ))}
                  <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedUsers.length} />
                  <UsersForm users={users} setTableReload={setTableLoading} setInfo={setInfo} />
                </>
              )
            }
          </>
        )}
      </React.Suspense>
    </>
  );
}

export default Users;
