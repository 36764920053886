/* eslint-env browser */

import React from "react";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import SearchContext from "../../components/contexts/SearchContext.jsx";
import useJwt from "../../components/contexts/useJwt.jsx";
import useDisplayedDeviceTypes from "../../components/hooks/useDisplayedDeviceTypes.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import NoResults from "../../components/primitives/NoResults.jsx";
import Pagination from "../../components/primitives/Pagination.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const StudyAccessoryKitsHeader = React.lazy(
  () => import(/* webpackPrefetch: true */ "./StudyAccessoryKitsHeader.jsx")
);
const StudyAccessoryKitsRow = React.lazy(
  () => import(/* webpackPrefetch: true */ "./StudyAccessoryKitsRow.jsx")
);
const StudyAccessoryKitsForm = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/StudyAccessoryKitsForm.jsx")
);

// refresh every 60 seconds to keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 60 * 1000;

//---------------------------------------------------------------------------
// Search configuration
//---------------------------------------------------------------------------
const searchFields = {
  part: "partNumber",
  description: "description",
  name: "name",
  "device-types": "deviceTypeFirmwareIds",
};

const defaultSort = {
  field: "name",
  reverse: false,
};
const pageSize = 50;

//--------------------------------------------------------------------------
export function allowStudyAccessoryKits(isInAnyRole, userFacilityBilling) {
  if (arguments.length !== 2) {
    throw new Error("Wrong number of arguments");
  }
  return isInAnyRole(["tzAdmin", "warehouse", "facilityAdmin"]) && userFacilityBilling === "independent";
}

function StudyAccessoryKits() {
  //---------------------------------------------------------------------------
  // Determine displayed values
  //---------------------------------------------------------------------------
  const displayedDeviceTypes = useDisplayedDeviceTypes();
  const deviceTypesOptions = React.useMemo(
    () => Object.entries(displayedDeviceTypes).map(([id, name]) => ({id, name})),
    [displayedDeviceTypes]
  );

  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {isInAnyRole, userFacilityBilling, userFacilityId} = useJwt();
  const searchHelper = React.useMemo(
    () => [
      {label: "Has the words", keyword: "+", variant: "global"},
      {label: "Doesn't have", keyword: "-", variant: "global"},
      {label: "Part Number", keyword: "part", variant: "negatable"},
      {label: "Description", keyword: "description", variant: "negatable"},
      {
        label: "Name",
        keyword: "name",
        variant: "negatable",
      },
      {
        label: "Device Types",
        keyword: "device-types",
        variant: "negatable",
        options: deviceTypesOptions,
      },
    ],
    [deviceTypesOptions]
  );

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Load data from the API
  //
  // Study Accessory Kits
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const [studyAccessoryKits, setStudyAccessoryKits] = React.useState([]);
  const getStudyAccessoryKits = React.useCallback(async () => {
    try {
      const {data: accessoriesResponse} = await axios({
        method: "get",
        url: `/facilities/${userFacilityId}/study-accessory-kits`,
      });

      setStudyAccessoryKits(accessoriesResponse);
    } catch (err) {
      setError(err.message);
    }
    setTableLoading(false);
  }, [userFacilityId]);

  useInterval(getStudyAccessoryKits, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const {search, setSearch, setSearchHelper, setSearchFields} = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      setSearchHelper("");
      setSearchFields("");
    };
  }, [searchHelper, setSearchHelper, setSearchFields]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch("");
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredAccessories = useFilter(studyAccessoryKits, search, searchFields);
  const [sortedAccessories, handleSortSelection, sort] = useSort(filteredAccessories, {defaultSort});

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageAccessories = React.useMemo(
    () => sortedAccessories.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedAccessories]
  );
  React.useEffect(() => setPage(0), [search]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowStudyAccessoryKits(isInAnyRole, userFacilityBilling)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Study Accessory Kits - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Display a message if there are no matching results, instead of the table
        //---------------------------------------------------------------------------
        !tableLoading && sortedAccessories.length === 0 && (
          <>
            <NoResults />
            <StudyAccessoryKitsForm facilityId={userFacilityId} setTableReload={setTableLoading} />
          </>
        )
      }
      {
        //---------------------------------------------------------------------------
        // Render the table and the FAB
        //---------------------------------------------------------------------------
        !tableLoading && sortedAccessories.length > 0 && (
          <>
            <StudyAccessoryKitsHeader sort={sort} setSort={handleSortSelection} />
            {pageAccessories.map((accessory) => (
              <StudyAccessoryKitsRow
                key={accessory.id}
                studyAccessoryKit={accessory}
                facilityId={userFacilityId}
                alwaysOpen={pageAccessories.length === 1}
                setTableReload={setTableLoading}
              />
            ))}
            <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedAccessories.length} />
            <StudyAccessoryKitsForm facilityId={userFacilityId} setTableReload={setTableLoading} />
          </>
        )
      }
    </>
  );
}

export default StudyAccessoryKits;
