/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const AboutBitrhythmCard = React.lazy(() => import(/* webpackPrefetch: true */ "./AboutBitRhythmCard.jsx"));
const ContactCard = React.lazy(() => import(/* webpackPrefetch: true */ "./ContactCard.jsx"));
const ResourcesCard = React.lazy(() => import(/* webpackPrefetch: true */ "./ResourcesCard.jsx"));

//--------------------------------------------------------------------------
export function allowSupport(isInAnyRole) {
  return isInAnyRole(["tzAdmin", "warehouse", "facilityAdmin"]);
}

function SupportPage() {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole} = useJwt();

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowSupport(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Support - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <ContactCard />
      <ResourcesCard />
      <AboutBitrhythmCard setError={setError} />
    </>
  );
}

export default SupportPage;
