/* eslint-disable no-param-reassign */
/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {DateTime} from "luxon";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import useJwt from "../../components/contexts/useJwt.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const UsageReportForm = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/UsageReportForm.jsx")
);
const UsageReportHeader = React.lazy(() => import(/* webpackPrefetch: true */ "./UsageReportHeader.jsx"));
const UsageReportRow = React.lazy(() => import(/* webpackPrefetch: true */ "./UsageReportRow.jsx"));

//--------------------------------------------------------------------------
export function allowUsageReport(isInAnyRole) {
  return isInAnyRole(["tzAdmin"]);
}

function UsageReport() {
  // ---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // Error management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // State management
  //---------------------------------------------------------------------------
  // Default to the previous month.
  const [usageReportDate, setUsageReportDate] = React.useState(
    DateTime.local().minus({months: 1}).startOf("month")
  );
  const [billableFacilities, setBillableFacilities] = React.useState([]);
  const [exemptFacilities, setExemptFacilities] = React.useState([]);
  const [facilityInvoices, setFacilityInvoices] = React.useState(new Map());
  const [tableLoading, setTableLoading] = React.useState(true);

  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const getUsageReports = React.useCallback(async () => {
    // Reset states for new API call
    setBillableFacilities([]);
    setExemptFacilities([]);
    setFacilityInvoices(new Map());

    const {year, month} = usageReportDate;
    const selectedDate = DateTime.utc(Number(year), Number(month));
    const startDate = selectedDate.startOf("month").toISO();
    const endDate = selectedDate.endOf("month").toISO();

    try {
      const [{data: facilities}, {data: reports}, {data: invoices}] = await Promise.all([
        axios({
          url: "/facilities",
          method: "get",
        }),
        axios({
          url: `/reports/billing?start=${startDate}&end=${endDate}`,
          method: "get",
        }),
        axios({
          url: `/facilities/invoices/device/${year}/${month}`,
          method: "get",
        }),
      ]);

      // Add BitRhythm connections and all facility info to each facility in the billing report
      Object.entries(reports.facilities).forEach(([facilityId, facility]) => {
        // Sort the billable devices by holter days and then TZSerial
        facility.billableCellularDevices.sort((a, b) => {
          if (a.holterDays < b.holterDays) {
            return 1;
          }
          if (a.holterDays > b.holterDays) {
            return -1;
          }
          return a.tzSerial < b.tzSerial ? 1 : -1;
        });

        facility.numCellularDevices = facility.billableCellularDevices.length;

        facility.numFullDisclosureDays = facility.billableCellularDevices.reduce(
          (previousValue, currentDevice) => previousValue + currentDevice.holterDays,
          0
        );

        facility.numNonCellularDevices = facility.billableNonCellularDevices.length;

        // All billed noncellular devices are forwarding to BitRhythm
        facility.numBitrhythmDevices =
          facility.billableCellularDevices.filter((device) => device.connectedToBitRhythm).length +
          facility.numNonCellularDevices;

        const currentFacilityInfo = facilities.find((f) => f.id === facilityId);
        Object.assign(facility, currentFacilityInfo);
      });

      const facilityInvoicesMap = new Map();
      invoices.forEach((invoice) => {
        facilityInvoicesMap.set(invoice.facilityId, invoice);
      });

      const billable = facilities
        .filter((facility) => {
          const facilityReport = reports.facilities[facility.id];
          return (
            !facilityReport ||
            (!facility.billingExempt &&
              (facilityReport?.numCellularDevices > 0 || facilityReport?.numBitrhythmDevices > 0))
          );
        })
        .map(({id}) => reports.facilities[id])
        .filter((obj) => obj !== undefined);

      const exempt = facilities
        .filter((facility) => {
          const facilityReport = reports.facilities[facility.id];
          return (
            !facilityReport ||
            facility.billingExempt ||
            (facilityReport?.numCellularDevices < 1 && facilityReport?.numBitrhythmDevices < 1)
          );
        })
        .map(({id}) => reports.facilities[id])
        .filter((obj) => obj !== undefined);

      setBillableFacilities(billable);
      setExemptFacilities(exempt);
      setFacilityInvoices(facilityInvoicesMap);
    } catch (err) {
      setError(err.message);
    }

    setTableLoading(false);
  }, [usageReportDate]);

  React.useEffect(() => {
    if (tableLoading) {
      getUsageReports();
    }
  }, [tableLoading, getUsageReports]);

  //---------------------------------------------------------------------------
  // Sorting support
  //---------------------------------------------------------------------------
  const defaultSort = {
    field: "name",
    reverse: false,
    caseSensitive: false,
  };
  const [sortedBillableFacilities, billableHandleSortSelection, billableSort] = useSort(billableFacilities, {
    defaultSort,
  });
  const [sortedExemptFacilities, exemptHandleSortSelection, exemptSort] = useSort(exemptFacilities, {
    defaultSort,
  });

  const clickedExport = React.useCallback(async () => {
    const yearMonth = usageReportDate.toFormat("yyyy_MM");

    const csvToExport = [
      ["Billing Month", "Exported On"],
      [usageReportDate.toFormat("MMMM yyyy"), DateTime.now().toLocaleString()],
      [""],
      ["Invoicing Required Facilities"],
    ];

    const cellularDevices = [
      [""],
      ["Cellular Devices"],
      ["Facility", "Device", "Full Disclosure Days", "Inbox Device"],
    ];
    const nonCellularDevices = [[""], ["Non-cellular Devices"], ["Facility", "Device", "Inbox Device"]];

    if (sortedBillableFacilities.length > 0) {
      csvToExport.push([
        "Facility Name",
        "Billable Cellular Devices",
        "Billable Non-cellular Devices",
        "Full Disclosure Days",
        "BitRhythm Devices",
        "Address",
        "Email",
        "Inbox Contract",
        "Notes",
      ]);
      sortedBillableFacilities.forEach((facility) => {
        const {address} = facility;
        const userFriendlyAddress = `${address.street} ${address.city}${
          address.city && address.state ? "," : ""
        } ${address.state} ${address.zipCode} ${address.country}`;
        csvToExport.push([
          facility.name,
          facility.numCellularDevices,
          facility.numNonCellularDevices,
          facility.numFullDisclosureDays,
          facility.numBitrhythmDevices,
          userFriendlyAddress,
          facility.email,
          facility.inboxAccess,
          facility.additionalInfo,
        ]);

        if (facility.billableCellularDevices.length > 0) {
          cellularDevices.push(
            ...facility.billableCellularDevices.map((cellularDevice) => [
              facility.name,
              cellularDevice.tzSerial,
              cellularDevice.holterDays,
              cellularDevice.connectedToBitRhythm,
            ])
          );
        }

        if (facility.billableNonCellularDevices?.length > 0) {
          nonCellularDevices.push(
            ...facility.billableNonCellularDevices.map((nonCellularDevice) => [
              facility.name,
              nonCellularDevice.tzSerial,
              nonCellularDevice.connectedToBitRhythm,
            ])
          );
        }
      });
    }

    csvToExport.push([""], ["Invoicing Not Required Facilities"]);

    if (sortedExemptFacilities.length > 0) {
      csvToExport.push([
        "Facility Name",
        "Billable Cellular Devices",
        "Billable Non-cellular Devices",
        "Full Disclosure Days",
        "BitRhythm Devices",
        "Address",
        "Email",
        "Inbox Contract",
        "Notes",
      ]);

      sortedExemptFacilities.forEach((facility) => {
        const {address} = facility;
        const userFriendlyAddress = `${address.street} ${address.city}${
          address.city && address.state ? "," : ""
        } ${address.state} ${address.zipCode} ${address.country}`;
        csvToExport.push([
          facility.name,
          facility.numCellularDevices,
          facility.numNonCellularDevices,
          facility.numFullDisclosureDays,
          facility.numBitrhythmDevices,
          userFriendlyAddress,
          facility.email,
          facility.inboxAccess,
          facility.additionalInfo,
        ]);
      });
    }

    csvToExport.push(...cellularDevices);
    csvToExport.push(...nonCellularDevices);

    let csvBuilder = "";
    csvToExport.forEach((line) => {
      line.forEach((item) => {
        if (typeof item === "boolean") {
          // Handle booleans
          csvBuilder += `"${item ? "Yes" : "No"}",`;
        } else if (typeof item === "string" && item.includes(",")) {
          // Strings with commas must be handled differently
          csvBuilder += `"${item.replaceAll(/"/g, "'")}",`;
        } else if (typeof item === "string") {
          // Handle strings
          csvBuilder += `="${item.replaceAll(/"/g, "'")}",`;
        } else {
          // Handle numbers, etc.
          csvBuilder += `="${item}",`;
        }
      });
      csvBuilder += `\n`;
    });

    const file = new Blob([csvBuilder], {type: "text/csv"});
    const hiddenElement = document.createElement("a");
    hiddenElement.href = URL.createObjectURL(file);
    hiddenElement.target = "_blank";
    hiddenElement.download = `BitRhythmUsageReport_${yearMonth}.csv`;
    hiddenElement.click();
  }, [sortedBillableFacilities, sortedExemptFacilities, usageReportDate]);

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowUsageReport(isInAnyRole)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Usage Report - BitRhythm Admin</title>
      </Helmet>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {
        //---------------------------------------------------------------------------
        // Render the usage report table
        //---------------------------------------------------------------------------
        !tableLoading && (
          <>
            <Stack
              direction="row"
              spacing={3}
              sx={{justifyContent: "space-between", alignItems: "flex-end", mt: 3}}
            >
              <Stack spacing={2} direction="row" sx={{alignItems: "flex-end"}}>
                <Typography variant="h5">Usage Report</Typography>
                <Typography variant="subtitle1">{usageReportDate.toFormat("MMMM yyyy")}</Typography>
              </Stack>
              <Stack spacing={2} direction="row">
                <Button
                  color="secondary"
                  data-cy="export-usage-report-button"
                  variant="outlined"
                  onClick={clickedExport}
                  sx={{mr: 3}}
                >
                  Export
                </Button>
                <UsageReportForm
                  usageReportDate={usageReportDate}
                  setUsageReportDate={setUsageReportDate}
                  setTableReload={setTableLoading}
                />
              </Stack>
            </Stack>

            {sortedBillableFacilities.length > 0 && (
              <Box
                sx={{mt: 4, p: 2, backgroundColor: "background.grey", borderRadius: "4px"}}
                data-cy="invoicing-required-section"
              >
                <Typography variant="h6" sx={{mb: 2}}>
                  Invoicing Required
                </Typography>

                <UsageReportHeader sort={billableSort} setSort={billableHandleSortSelection} />
                {sortedBillableFacilities.map((billableFacility) => (
                  <UsageReportRow
                    key={billableFacility.name}
                    data-cy={`${billableFacility.id}-usage-report-card`}
                    facility={billableFacility}
                    invoices={facilityInvoices}
                    usageReportDate={usageReportDate}
                    setTableReload={setTableLoading}
                    setError={setError}
                  />
                ))}
              </Box>
            )}

            {sortedExemptFacilities.length > 0 && (
              <Box
                sx={{mt: 4, p: 2, backgroundColor: "background.grey", borderRadius: "4px"}}
                data-cy="invoicing-not-required-section"
              >
                <Typography variant="h6">Invoicing Not Required</Typography>
                <Typography variant="body2" sx={{mb: 2, color: "text.secondary"}}>
                  <i>
                    An invoice is not required if the facility is marked as exempt or there are no billable
                    devices for the given month
                  </i>
                </Typography>

                <UsageReportHeader sort={exemptSort} setSort={exemptHandleSortSelection} />
                {sortedExemptFacilities.map((exemptFacility) => (
                  <UsageReportRow
                    key={exemptFacility.name}
                    data-cy={`${exemptFacility.id}-usage-report-card-exempt`}
                    facility={exemptFacility}
                    invoices={facilityInvoices}
                    usageReportDate={usageReportDate}
                    setTableReload={setTableLoading}
                    setError={setError}
                  />
                ))}
              </Box>
            )}
          </>
        )
      }
    </>
  );
}

export default UsageReport;
